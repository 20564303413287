const containsEmail = (url) => url.match(/@/) != null;
const containsSinginToken = (url) => (url.match(/\?.*signin=/) || url.match(/\?.*auth_token=/)) != null;

const getDecodedUrl = (inputUrl) => {
  const url = new URL(inputUrl);
  const params = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of url.searchParams) {
    params.push(`${key}=${value}`);
  }

  return `${url.origin}${url.pathname}?${params.join('&')}${url.hash}`;
};

const containsPrivateInfo = (url) => {
  const decodedUrl = getDecodedUrl(url);
  return containsEmail(decodedUrl) || containsSinginToken(decodedUrl);
};

const pageIsPrivate = () => containsPrivateInfo(window.location.href);

export { containsPrivateInfo, pageIsPrivate };
