import { xhrFetch } from './xhrFetch';

const URL = 'https://api.babbel.io/gamma/v1.0.0/en/geoip/me';

const fetchGeoData = () =>
  xhrFetch(URL)
    .then((res) => JSON.parse(res))
    .then((data) => data.geoip);

export default fetchGeoData;
export { URL };
