const parseDate = (date) => date.toISOString().split('T')[0]; // 2016-02-18T23:59:48.039Z
const isValidDate = (date) => !isNaN(date.valueOf()) && date >= new Date(2015, 0, 1);

const convertDate = (value) => {
  const date = value instanceof Date ? value : new Date(value);
  return isValidDate(date) ? parseDate(date) : undefined;
};

const parseDateAndValidate = (dateParam) => {
  const date = new Date(dateParam);
  if (isNaN(date.getTime())) {
    throw new Error(`"${dateParam}" is not a valid date`);
  }
  return date;
};

export { convertDate, parseDateAndValidate };
