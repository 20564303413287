import babbelTracker from '@lessonnine/tracker.js';
import { currentEnvironment } from './environment';

const TRACKER_URLS = {
  production: 'https://api.babbel.io/gamma/v1/events',
  staging: 'https://api.babbel-staging.io/gamma/v1/events',
  development: '/gamma/v1/events'
};

const trackerUrl = () => TRACKER_URLS[currentEnvironment()];

const tracker = new babbelTracker.Tracker({
  url: trackerUrl(),
  trackingUuidName: 'babbeltrackinguuid',
  useFetch: true
});

export default tracker;
export { trackerUrl };
