export function delay(interval) {
  return new Promise((resolve) => setTimeout(resolve, interval));
}

/**
 * This function calls `getResult` with an `interval` delay unless
 * there is a truthy result or the `timeout` is exceeded. It returns
 * the last result.
 */
export async function waitForResult(getResult, timeout, interval) {
  const startTime = Date.now();

  let result = getResult();
  while (!result && Date.now() - startTime < timeout) {
    await delay(interval);
    result = getResult();
  }

  return result;
}
