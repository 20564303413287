const OK = 200;

const xhrFetch = (url) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url);
    request.send(null);

    request.onload = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === OK) {
          resolve(request.responseText);
        } else {
          reject(new Error(`XHR request returned ${request.status}`));
        }
      }
    };

    request.timeout = 2000;
    request.ontimeout = () => {
      reject(new Error(`XHR request timeout: ${url}`));
    };

    request.onerror = (err) => {
      reject(new Error(`XHR request error: ${err}`));
    };
  });
};

export { xhrFetch };
