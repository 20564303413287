import { pageIsPrivate } from '../utils/urlAnalyser';
import { documentReady } from '../utils/documentReady';
import { currentHost } from '../utils/url';

const GTM_EVENT = 'gtm.js';
const GTM_SCRIPT_ID = 'gtm-script';
const B2B_GTM_ID = 'GTM-5R6G5BT';
const DEFAULT_GTM_ID = 'GTM-MDD3PT';
const GTM_BASE_URL = 'https://www.googletagmanager.com/gtm.js?id=';

const GTM_IDS = [
  { id: B2B_GTM_ID, hostPattern: /babbelforbusiness\.com/i },
  { id: B2B_GTM_ID, hostPattern: /babbelfuerunternehmen\.de/i },
  { id: B2B_GTM_ID, hostPattern: /babbel-for-business\.(preview|site)\.strattic\.io/i },
  { id: B2B_GTM_ID, hostPattern: /babbel-b2b\.(preview|site)\.strattic\.io/i },
  { id: B2B_GTM_ID, hostPattern: /babbel-b2b-eng\.(preview|site)\.strattic\.io/i },
  { id: B2B_GTM_ID, hostPattern: /business\.babbel\.com/i }
];

export const getGTMIdByHost = (host) => {
  const matchingGtm = GTM_IDS.find((ds) => new RegExp(ds.hostPattern).test(host));
  return matchingGtm ? matchingGtm.id : DEFAULT_GTM_ID;
};

export const createGTMScript = (gtmId) => {
  const scriptContainer = document.createElement('script');
  scriptContainer.id = GTM_SCRIPT_ID;
  scriptContainer.src = GTM_BASE_URL + gtmId;
  scriptContainer.async = true;

  return scriptContainer;
};

export const loadGTM = async () => {
  if (pageIsPrivate()) return;

  if (document.getElementById(GTM_SCRIPT_ID)) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: GTM_EVENT, 'gtm.start': new Date().getTime() });

  const scriptContainer = createGTMScript(getGTMIdByHost(currentHost()));

  await documentReady();
  document.body.appendChild(scriptContainer);
};
