import pkg from '../../package.json';
import { getFbpCookie, parseFbpCookie } from '../utils/cookies';
import tracker from '../utils/tracker';
import { waitForResult } from '../utils/timers';
import { isLocalStorageAvailable } from '../utils/storage';
import { parseDateAndValidate } from '../utils/dateParser';

const FBP_COOKIE_TIMEOUT = 5000; // 5 seconds
const FBP_COOKIE_INTERVAL = 200;
const FBP_COOKIE_LAST_SENT_EVENT_ITEM_NAME = 'lastSentFbpCookieEvent';
const FBP_COOKIE_LAST_SENT_EVENT_STALE_PERIOD = 60 * 60 * 1000; // 60 minutes in ms

export async function trackFbpCookie(
  user,
  consentPreferences,
  timeout = FBP_COOKIE_TIMEOUT,
  interval = FBP_COOKIE_INTERVAL
) {
  if (!consentPreferences.targeting_cookies) {
    console.info('Targeting cookie consent is not given, fbp cookie tracking is disabled');
    return;
  }

  const fbpCookie = await waitForResult(getFbpCookie, timeout, interval);
  if (!fbpCookie) {
    console.warn('Fbp cookie has not appeared, nothing to track');
    return;
  }

  const parsedFbpCookie = parseFbpCookie(fbpCookie);
  const event = createEvent(user, parsedFbpCookie);

  // The logic below goes like this: we send the event by default, so we only
  // skip it once we're sure that it was sent recently. In all other cases,
  // we send it.
  if (isLocalStorageAvailable()) {
    const lastSentEvent = getLastSentEvent();
    if (lastSentEvent && !shouldSendEvent(event, lastSentEvent)) {
      console.info("Not sending fbp cookie info because it's already been sent");
    } else {
      tracker.track(event);
      setLastSentEvent(event);
    }
  } else {
    console.warn('Local storage is not available, just tracking fbp cookie info');
    tracker.track(event);
  }
}

export function createEvent(user, parsedFbpCookie) {
  const { value, createdAt } = parsedFbpCookie;

  return {
    name: 'partner_cookie:updated',
    version: 1,
    payload: {
      created_at: new Date().toISOString(),
      uuid: user.uuid,
      type: user.uuid ? 'babbel_user' : 'visitor',
      marketing_tracker_version: pkg.version,
      cookie: {
        type: 'fbp',
        value,
        ...(createdAt && { created_at: createdAt.toISOString() })
      }
    }
  };
}

export function setLastSentEvent(event) {
  window.localStorage.setItem(FBP_COOKIE_LAST_SENT_EVENT_ITEM_NAME, JSON.stringify(event));
}

export function getLastSentEvent() {
  const value = window.localStorage.getItem(FBP_COOKIE_LAST_SENT_EVENT_ITEM_NAME);
  return value ? JSON.parse(value) : value;
}

export function shouldSendEvent(event, lastSentEvent, stalePeriod = FBP_COOKIE_LAST_SENT_EVENT_STALE_PERIOD) {
  try {
    const currentCreatedAt = parseDateAndValidate(event.payload.created_at).getTime();
    const lastCreatedAt = parseDateAndValidate(lastSentEvent.payload.created_at).getTime();
    if (
      currentCreatedAt - lastCreatedAt < stalePeriod &&
      event.payload.cookie.value === lastSentEvent.payload.cookie.value &&
      event.payload.type === lastSentEvent.payload.type &&
      event.payload.uuid === lastSentEvent.payload.uuid &&
      event.version === lastSentEvent.version
    ) {
      return false;
    }
  } catch (error) {
    console.error('Error while comparing fbp cookie info:', error);
    return true;
  }

  return true;
}
