/**
 * This function returns true if the localStorage is available in the current
 * environment. It's borrowed from Modernizr, you can find more info there
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
 */
export function isLocalStorageAvailable() {
  const test = '__isLocalStorageAvailable__';
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (error) {
    return false;
  }
}
