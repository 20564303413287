import { convertDate } from './utils/dateParser';
import fetchGeoData from './utils/fetchGeoData';
import { loadGTM } from './gtm/load';
import { pageIsPrivate } from './utils/urlAnalyser';
import { loadOnetrust } from './gtm/onetrust';

const MARKETING_TRACKER_INIT = 'marketingTracker.init';

const getContentGroup = () => {
  const tag = document.querySelector('meta[name="marketing_content_group"]');
  return tag ? tag.content : undefined;
};

const pushGeoData = async (geoip) => {
  if (geoip.countryAlpha3) {
    const { city, countryAlpha3, region } = geoip;
    const geo_data = {
      country_alpha3: countryAlpha3,
      ...(city && { city }),
      ...(region && { region })
    };
    window.dataLayer.push({ event: 'geoip.load', geo_data });
  } else {
    try {
      const geoip = await fetchGeoData();
      const { city, country_code3, region } = geoip;
      const geo_data = {
        country_alpha3: country_code3,
        ...(city && { city }),
        ...(region && { region })
      };
      window.dataLayer.push({ event: 'geoip.load', geo_data });
    } catch (err) {
      // trigger GEOIP_LOADING_FINISHED to continue with GTM loading
      window.dataLayer.push({ event: 'geoip.load', geo_data: {} });
    }
  }
};

const init = ({ geoData = {}, locale, learnLanguageAlpha3, userData = {} }) => {
  if (pageIsPrivate()) {
    return Promise.reject(new Error('Page is private so GTM will not be called from this page'));
  }

  if (typeof window.dataLayer === 'undefined') {
    throw new Error('DataLayer not available. Try using the `load` method first.');
  }

  loadOnetrust(userData);

  window.dataLayer.push({
    event: MARKETING_TRACKER_INIT,
    marketing_content_group: getContentGroup(),
    lead_country_alpha3: userData.leadCountryAlpha3,
    lead_date: convertDate(userData.leadDate),
    learn_language_alpha3: userData.learnLanguageAlpha3 || learnLanguageAlpha3,
    locale,
    uuid: userData.uuid
  });

  return pushGeoData(geoData);
};

const buildEventObject = ({ countryAlpha3, email, eventName, leadDate, learnLanguageAlpha3, uuid }) => {
  if (!eventName) throw new Error('"eventName" field is required when building event objects');

  const eventObject = {
    event: eventName,
    lead_date: convertDate(leadDate),
    learn_language_alpha3: learnLanguageAlpha3,
    uuid
  };
  if (email && countryAlpha3 === 'USA') {
    eventObject.email = email;
  }

  return eventObject;
};

const trackConversionFor =
  (eventName) =>
  ({ uuid, leadDate, learnLanguageAlpha3, countryAlpha3, email }) => {
    if (pageIsPrivate()) return;
    if (!window.dataLayer) throw new Error('DataLayer not available.');

    const eventDetails = buildEventObject({
      countryAlpha3,
      email,
      eventName,
      leadDate,
      learnLanguageAlpha3,
      uuid
    });

    window.dataLayer.push(eventDetails);
  };

const trackQuestionAnswered = ({ question, answer }) => {
  if (pageIsPrivate()) return;
  if (!window.dataLayer) throw new Error('DataLayer not available.');

  window.dataLayer.push({
    event: 'question:answered',
    payload: { question, answer }
  });
};

const trackSalesConversion = (
  { id, couponCode, price, netAmount, taxAmount, currency, subscriptionKeyCode, skuKeyCode, productTitle },
  { uuid, leadDate, learnLanguageAlpha3 }
) => {
  if (pageIsPrivate()) return;
  if (!window.dataLayer) throw new Error('DataLayer not available.');

  window.dataLayer.push({
    event: 'purchase_completed',
    lead_date: convertDate(leadDate),
    learn_language_alpha3: learnLanguageAlpha3,
    uuid,
    purchase: {
      purchase_id: id,
      coupon_code: couponCode,
      price,
      currency,
      subscription_mode_key_code: subscriptionKeyCode
    },
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: id,
          revenue: price,
          tax: taxAmount,
          coupon: couponCode
        },
        products: [
          {
            id: skuKeyCode,
            name: productTitle,
            price: netAmount,
            quantity: 1
          }
        ]
      }
    }
  });
};

export default {
  init,
  load: loadGTM,
  trackQuestionAnswered,
  trackSalesConversion,
  trackSimpleLead: trackConversionFor('registration_completed'),
  trackVerifiedLead: trackConversionFor('email_verified')
};
